import React, { useRef, useState, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import parse from "html-react-parser"
import PropTypes from "prop-types"

import { Headline, HeadlineSizes, Loader } from "ui"
import SubpageLayout from "layouts/SubpageLayout"
import { Wrapper, WrapperSizes, SEO, Footer } from "components"
import { gsap } from "gsap"
import S from "./PolicyPrivacyTemplate.styled"

const query = graphql`
  {
    allWpPage(filter: { privacy: { privacyLang: { regex: "/ES|EN/" } } }) {
      edges {
        node {
          title
          content
          privacy {
            privacyLang
          }
        }
      }
    }
  }
`

const PolicyPrivacyTemplate = ({ location, lang }) => {
  const { allWpPage: { edges: languageDataVersions } } = useStaticQuery(query) // eslint-disable-line
  const [pageContent, setPageContent] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const {
      node: { title, content },
    } = languageDataVersions.find(
      ({ node: { privacy } }) => privacy.privacyLang === lang
    )

    setPageContent({ title, content })

    const id = setTimeout(() => setLoading(false), 500)
    return () => clearTimeout(id)
  }, [])

  const sectionRef = useRef(null)

  useEffect(() => {
    if (loading) return

    const tl = gsap.timeline()

    tl.fromTo(
      sectionRef.current,
      { y: "+=50", opacity: 0 },
      { y: 0, opacity: 1, duration: 0.5 }
    )
  }, [sectionRef, loading])

  return (
    <>
      <SEO title={pageContent?.title || ""} />
      <SubpageLayout path={location.pathname}>
        {loading ? (
          <Loader />
        ) : (
          <>
            <Wrapper size={WrapperSizes.SMALL} _ref={sectionRef}>
              <Headline tag="h1" sizeType={HeadlineSizes.XL}>
                {pageContent?.title}
              </Headline>
              <S.Content>
                {parse(pageContent?.content || "", {
                  trim: true,
                })}
              </S.Content>
            </Wrapper>
            <Footer />
          </>
        )}
      </SubpageLayout>
    </>
  )
}

PolicyPrivacyTemplate.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  lang: PropTypes.string.isRequired,
}

export default PolicyPrivacyTemplate
