import styled from "styled-components"
import { devices } from "theme/devices"

const Content = styled.div`
  display: block;
  padding: 0;
  color: ${({ theme }) => theme.neutral60};
  font-size: 1.6rem;
  line-height: 1.5;
  font-weight: 400;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    padding: 0;
    margin: 1.5em 0 0.5em;
    font-weight: 700;
  }

  h1 {
    font-size: 2.8rem;

    ${devices.mobile} {
      font-size: 3rem;
    }

    ${devices.mobileL} {
      font-size: 3.6rem;
    }

    ${devices.tablet} {
      font-size: 3.8rem;
    }

    ${devices.desktop} {
      font-size: 4.4rem;
    }
  }

  h2 {
    font-size: 2.4rem;

    ${devices.mobile} {
      font-size: 2.5rem;
    }

    ${devices.mobileL} {
      font-size: 2.8rem;
    }

    ${devices.tablet} {
      font-size: 3.2rem;
    }

    ${devices.desktop} {
      font-size: 3.4rem;
    }
  }

  h3 {
    font-size: 2.2rem;

    ${devices.mobile} {
      font-size: 2.3rem;
    }

    ${devices.mobileL} {
      font-size: 2.4rem;
    }

    ${devices.tablet} {
      font-size: 2.6rem;
    }

    ${devices.desktop} {
      font-size: 2.8rem;
    }
  }

  h4 {
    font-size: 2rem;

    ${devices.mobile} {
      font-size: 2.1rem;
    }

    ${devices.mobileL} {
      font-size: 2.3rem;
    }

    ${devices.tablet} {
      font-size: 2.5rem;
    }

    ${devices.desktop} {
      font-size: 2.6rem;
    }
  }

  h5 {
    font-size: 1.8rem;

    ${devices.mobile} {
      font-size: 1.9rem;
    }

    ${devices.mobileL} {
      font-size: 2rem;
    }

    ${devices.tablet} {
      font-size: 2.1rem;
    }

    ${devices.desktop} {
      font-size: 2.2rem;
    }
  }

  h6 {
    font-size: 1.6rem;

    ${devices.mobile} {
      font-size: 1.7rem;
    }

    ${devices.mobileL} {
      font-size: 1.8rem;
    }

    ${devices.tablet} {
      font-size: 1.9rem;
    }

    ${devices.desktop} {
      font-size: 2rem;
    }
  }

  p {
    font-size: 1.6rem;
    margin: 0;
    padding: 0;
  }
`

export default { Content }
